import {
  ANNOTATION_EDIT_URL,
  ANNOTATION_LOCATION,

  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  Avatar,
  ChatIcon,
  InfoCard,

  Link,
} from '@backstage/core-components';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback } from 'react';

const CardTitle = (props) => (
  React.createElement(Box, { display: "flex", alignItems: "center",}
    , React.createElement(GroupIcon, { fontSize: "inherit",} )
    , React.createElement(Box, { ml: 1,}, props.title)
  )
);

/** @public */
export const GroupProfileCard = (props) => {
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const { entity: group } = useEntity();

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(group));
    alertApi.post({ message: 'Refresh scheduled', severity: 'info' });
  }, [catalogApi, alertApi, group]);

  if (!group) {
    return React.createElement(Alert, { severity: "error",}, "Group not found"  );
  }

  const {
    metadata: { name, description, annotations },
    spec: { profile },
  } = group;

  const childRelations = getEntityRelations(group, RELATION_PARENT_OF, {
    kind: 'Group',
  });
  const parentRelations = getEntityRelations(group, RELATION_CHILD_OF, {
    kind: 'group',
  });

  const entityLocation = annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');

  const entityMetadataEditUrl =
    group.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const displayName = profile?.displayName ?? name;
  const emailHref = profile?.email ? `mailto:${profile.email}` : '#';
  const infoCardAction = entityMetadataEditUrl ? (
    React.createElement(IconButton, {
      'aria-label': "Edit",
      title: "Edit Metadata" ,
      component: Link,
      to: entityMetadataEditUrl,}
    
      , React.createElement(EditIcon, null )
    )
  ) : (
    React.createElement(IconButton, { 'aria-label': "Edit", disabled: true, title: "Edit Metadata" ,}
      , React.createElement(EditIcon, null )
    )
  );

  const slackLink = group.metadata?.links?.find(a => a.icon === 'chat') ?? null;

  return (
    React.createElement(InfoCard, {
      title: React.createElement(CardTitle, { title: displayName,} ),
      subheader: description,
      variant: props.variant,
      action: 
        React.createElement(React.Fragment, null
          , allowRefresh && (
            React.createElement(IconButton, {
              'aria-label': "Refresh",
              title: "Schedule entity refresh"  ,
              onClick: refreshEntity,}
            
              , React.createElement(CachedIcon, null )
            )
          )
          , infoCardAction
        )
      ,}
    
      , React.createElement(Grid, { container: true, spacing: 3,}
        , React.createElement(Grid, { item: true, xs: 12, sm: 2, xl: 1,}
          , React.createElement(Avatar, { displayName: displayName, picture: profile?.picture,} )
        )
        , React.createElement(Grid, { item: true, md: 10, xl: 11,}
          , React.createElement(List, null
            , slackLink && (
              React.createElement(ListItem, null
                , React.createElement(ListItemIcon, null
                  , React.createElement(Tooltip, { title: slackLink.title ?? 'Chat',}
                    , React.createElement(ChatIcon, null )
                  )
                )
                , React.createElement(ListItemText, null
                  , React.createElement(Link, { to: slackLink.url,}, slackLink.title)
                )
              )
            )
            , profile?.email && (
              React.createElement(ListItem, null
                , React.createElement(ListItemIcon, null
                  , React.createElement(Tooltip, { title: "Email",}
                    , React.createElement(EmailIcon, null )
                  )
                )
                , React.createElement(ListItemText, null
                  , React.createElement(Link, { to: emailHref,}, profile.email)
                )
              )
            )

            , parentRelations.length ? (
              React.createElement(ListItem, null
                , React.createElement(ListItemIcon, null
                  , React.createElement(Tooltip, { title: "Parent Group" ,}
                    , React.createElement(AccountTreeIcon, null )
                  )
                )
                , React.createElement(ListItemText, null
                  , React.createElement(EntityRefLinks, {
                    entityRefs: parentRelations,
                    defaultKind: "Group",}
                  )
                )
              )
            ) : null

            , childRelations.length ? (
              React.createElement(ListItem, null
                , React.createElement(ListItemIcon, null
                  , React.createElement(Tooltip, { title: "Child Groups" ,}
                    , React.createElement(GroupIcon, null )
                  )
                )
                , React.createElement(ListItemText, null
                  , React.createElement(EntityRefLinks, {
                    entityRefs: childRelations,
                    defaultKind: "Group",}
                  )
                )
              )
            ) : null
          )
        )
      )
    )
  );
};
