import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,


} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { CatalogTable, } from '@backstage/plugin-catalog';
import {
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityListProvider,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,

  UserListPicker,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { EntityAudiencePicker } from '../EntityApiAudiencePicker/EntityApiAudiencePicker';

const createApiAudienceColumn = 

() => {
  return {
    title: 'API Audience',
    field: 'spec.type',
    render: ({ entity }) => {
      return entity.metadata.annotations?.['showpad.com/api-audience'];
    },
  };
};

const defaultColumns = [
  CatalogTable.columns.createNameColumn({ defaultKind: 'API' }),
  // CatalogTable.columns.createSystemColumn(),
  CatalogTable.columns.createOwnerColumn(),
  CatalogTable.columns.createSpecTypeColumn(),
  CatalogTable.columns.createSpecLifecycleColumn(),
  CatalogTable.columns.createMetadataDescriptionColumn(),
  createApiAudienceColumn(),
  // CatalogTable.columns.createTagsColumn(),
];

/**
 * ApiExplorerPageProps
 * @public
 */






/**
 * CustomApiExplorerPage
 * @public
 */
export const CustomApiExplorerPage = ({
  initiallySelectedFilter = 'all',
  columns,
  actions,
}) => {
  const configApi = useApi(configApiRef);
  const generatedSubtitle = `${
    configApi.getOptionalString('organization.name') ?? 'Backstage'
  } API Explorer`;
  return (
    React.createElement(PageWithHeader, {
      themeId: "apis",
      title: "Showpad APIs" ,
      subtitle: generatedSubtitle,}
    
      , React.createElement(Content, null
        , React.createElement(ContentHeader, { title: "API Catalog" ,}
          , React.createElement(SupportButton, null, "Need help with your API?"    )
        )
        , React.createElement(EntityListProvider, null
          , React.createElement(CatalogFilterLayout, null
            , React.createElement(CatalogFilterLayout.Filters, null
              , React.createElement(EntityKindPicker, { initialFilter: "api", hidden: true,} )
              , React.createElement(EntityTypePicker, null )
              , React.createElement(UserListPicker, { initialFilter: initiallySelectedFilter,} )
              , React.createElement(EntityOwnerPicker, null )
              , React.createElement(EntityLifecyclePicker, null )
              , React.createElement(EntityTagPicker, null )
              , React.createElement(EntityAudiencePicker, null )
            )
            , React.createElement(CatalogFilterLayout.Content, null
              , React.createElement(CatalogTable, {
                columns: columns || defaultColumns,
                actions: actions,}
              )
            )
          )
        )
      )
    )
  );
};
