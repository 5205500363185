import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useEntityList } from '@backstage/plugin-catalog-react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {

  EntityApiAudienceFilter,
} from '../filters/EntityApiAudienceFilter';


const useStyles = makeStyles(
  { input: {} },
  { name: 'CatalogReactEntityApiAudiencePicker' },
);

const icon = React.createElement(CheckBoxOutlineBlankIcon, { fontSize: "small",} );
const checkedIcon = React.createElement(CheckBoxIcon, { fontSize: "small",} );

export const EntityAudiencePicker = () => {
  const classes = useStyles();

  // The audiences key is recognized due to the CustomFilter generic
  const {
    filters: { audiences },
    updateFilters,
    backendEntities,
    queryParameters: { audiences: audiencesParameter },
  } = useEntityList();

  const queryParamAudiences = useMemo(
    () => [audiencesParameter].flat().filter(Boolean) ,
    [audiencesParameter],
  );

  const [selectedAudiences, setSelectedAudiences] = useState(
    queryParamAudiences.length ? queryParamAudiences : audiences?.values ?? [],
  );

  // Set selected api-audiences on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamAudiences.length) {
      setSelectedAudiences(queryParamAudiences);
    }
  }, [queryParamAudiences]);

  useEffect(() => {
    updateFilters({
      audiences: selectedAudiences.length
        ? new EntityApiAudienceFilter(selectedAudiences)
        : undefined,
    });
  }, [selectedAudiences, updateFilters]);

  const availablesAudiences = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .map(
              (e) =>
                e.metadata.annotations?.['showpad.com/api-audience'],
            )
            .filter(Boolean) ,
        ),
      ].sort(),
    [backendEntities],
  );

  if (!availablesAudiences.length) return null;

  const audienceOptions = [
    'component-internal',
    'company-internal',
    'external-partner',
    'external-public',
  ];

  return (
    React.createElement(Box, { pb: 1, pt: 1,}
      , React.createElement(Typography, { variant: "button", component: "label",}, "API Audience"

        , React.createElement(Autocomplete, {
          multiple: true,
          options: audienceOptions,
          value: selectedAudiences,
          onChange: (_, value) => setSelectedAudiences(value),
          renderOption: (option, { selected }) => (
            React.createElement(FormControlLabel, {
              control: 
                React.createElement(Checkbox, {
                  icon: icon,
                  checkedIcon: checkedIcon,
                  checked: selected,}
                )
              ,
              label: option,}
            )
          ),
          size: "small",
          popupIcon: React.createElement(ExpandMoreIcon, { 'data-testid': "apiaudience-picker-expand",} ),
          renderInput: params => (
            React.createElement(TextField, {
              ...params,
              className: classes.input,
              variant: "outlined",}
            )
          ),}
        )
      )
    )
  );
};
