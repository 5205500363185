/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { Link, makeStyles } from '@material-ui/core';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import GraphIcon from '@material-ui/icons/Share';
import React, { } from 'react';
import { NavLink } from 'react-router-dom';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  linkClosed: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  svg: {
    width: 'auto',
    height: 30,
  },
  svgClosed: {
    width: 24,
    height: 'auto',
  },
  path: {
    fill: '#fff',
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    React.createElement('div', { className: classes.root,}
      , React.createElement(Link, {
        component: NavLink,
        to: "/",
        underline: "none",
        className: classes.link,
        'aria-label': "Home",}
      
        , isOpen ? (
          React.createElement(LogoFull, { classes: { svg: classes.svg, path: classes.path },} )
        ) : (
          React.createElement(LogoIcon, { classes: { svg: classes.svgClosed },} )
        )
      )
    )
  );
};

export const Root = ({ children }) => (
  React.createElement(SidebarPage, null
    , React.createElement(Sidebar, null
      , React.createElement(SidebarLogo, null )
      , React.createElement(SidebarGroup, { label: "Search", icon: React.createElement(SearchIcon, null ), to: "/search",}
        , React.createElement(SidebarSearchModal, null )
      )
      , React.createElement(SidebarDivider, null )
      , React.createElement(SidebarGroup, { label: "Menu", icon: React.createElement(MenuIcon, null ),}
        , React.createElement(SidebarItem, { icon: HomeIcon, to: "/", text: "Home",} )
        /* Global nav, not org-specific */
        , React.createElement(SidebarItem, { icon: ExtensionIcon, to: "api-docs", text: "APIs",} )
        , React.createElement(SidebarItem, {
          icon: CollectionsBookmarkIcon,
          to: "catalog",
          text: "Catalogs",}
        )
        , React.createElement(SidebarItem, { icon: LibraryBooks, to: "docs", text: "Docs",} )
        , React.createElement(SidebarItem, { icon: GraphIcon, to: "graph", text: "Graph",} )
        /* End global nav */
      )
      , React.createElement(SidebarSpace, null )
      , React.createElement(SidebarDivider, null )
      , React.createElement(SidebarGroup, {
        label: "Settings",
        icon: React.createElement(UserSettingsSignInAvatar, null ),
        to: "/settings",}
      
        , React.createElement(SidebarSettings, null )
      )
    )
    , children
  )
);
