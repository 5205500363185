import { Content, InfoCard, Page } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageToolkit,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import {
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import {
  Extension as ExtensionIcon,
  School as LearningIcon,
  Domain as DomainIcon,
} from '@material-ui/icons';
import React from 'react';
import LogoFull from '../Root/LogoFull';
import { OwnedEntities } from './OwnedEntities';









const tools = [
  {
    label: 'API Guidelines',
    url: 'https://showpad.pages.showpad.io/restful-api-guidelines',
    icon: React.createElement(ExtensionIcon, null ),
  },
  {
    label: 'Learning Hub',
    url: 'https://gtm.showpad.biz/webapp2/content/channels/168dda6a1a80511ae9bbc244a291cf24/5d6f7151108139c12f06f592e86d2d514febb20661766b5168e45deb220ce4c6',
    icon: React.createElement(LearningIcon, null ),
  },
  {
    label: 'Domain Events',
    url: 'https://gitlab.showpad.io/showpad/showpad-domain-events',
    icon: React.createElement(DomainIcon, null ),
  },
];

const gettingStarted = [
  {
    label: 'How to publish your API on Backstage',
    url: '/docs/default/component/backstage/guides/publish-api',
  },
  {
    label: 'Adding/updating team or user info',
    url: 'https://gitlab.showpad.io/showpad/backstage-data',
  },
  {
    label: 'API & Domain Event Review Process',
    url: 'https://backstage.tooling.showpad.io/docs/default/component/showpad-public-api/guides/review-process/',
  },
];

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: theme.palette.primary.main,
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();
  return (
    React.createElement(SearchContextProvider, null
      , React.createElement(Page, { themeId: "home",}
        , React.createElement(Content, null
          , React.createElement(Grid, { container: true, justifyContent: "center", spacing: 6,}
            , React.createElement(HomePageCompanyLogo, {
              className: container,
              logo: React.createElement(LogoFull, { classes: { svg, path }, isHomepageLogo: true,} ),}
            )
            , React.createElement(Grid, { container: true, item: true, xs: 12, alignItems: "center", direction: "row",}
              , React.createElement(HomePageSearchBar, {
                classes: { root: classes.searchBar },
                placeholder: "Search",}
              )
            )
            , React.createElement(Grid, { container: true, item: true, xs: 12,}
              , React.createElement(Grid, { item: true, xs: 12, md: 6,}
                , React.createElement(InfoCard, { title: "Getting started on Backstage"   ,}
                  , React.createElement(List, null
                    , gettingStarted.map(item => (
                      React.createElement(ListItem, { key: item.url,}
                        , React.createElement(Link, { href: item.url, target: "_blank",}
                          , React.createElement(ListItemText, { primary: item.label,} )
                        )
                      )
                    ))
                  )
                )
              )
              , React.createElement(Grid, { item: true, xs: 12, md: 6,}
                , React.createElement(HomePageStarredEntities, null )
              )
            )
            , React.createElement(Grid, { container: true, item: true, xs: 12,}
              , React.createElement(Grid, { item: true, xs: 12, md: 6,}
                , React.createElement(OwnedEntities, { title: "Your Owned Entities"  ,} )
              )
              , React.createElement(Grid, { item: true, xs: 12, md: 6,}
                , React.createElement(HomePageToolkit, { title: "Quick links" , tools: tools,} )
              )
            )
          )
        )
      )
    )
  );
};
