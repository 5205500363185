









export class EntityApiAudienceFilter  {
  constructor( values) {;this.values = values;}
  filterEntity(entity) {
    const apiAudience =
      entity.metadata.annotations?.['showpad.com/api-audience'];
    return apiAudience !== undefined && this.values.includes(apiAudience);
  }
}
