import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import {
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  entityRouteParams,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import {
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';

export const OwnedEntities = (props) => {
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const { value, loading, error } = useAsync(async () => {
    // Find logged in identity
    const backstageIdentity = await identityApi.getBackstageIdentity();

    // Fetch all entities owned by this identity.
    // These "ownershipEntityRefs" are set in auth.ts
    const response = await catalogApi.getEntities({
      filter: {
        kind: ['API', 'Component', 'Group'],
        'relations.ownedBy': backstageIdentity.ownershipEntityRefs,
      },
    });
    return response.items;
  }, [identityApi, catalogApi]);

  let innerContent;
  if (loading || loading) {
    innerContent = React.createElement(Progress, null );
  } else if (error) {
    innerContent = React.createElement(ResponseErrorPanel, { error: error,} );
  } else if (value?.length === 0) {
    innerContent = (
      React.createElement(Typography, { variant: "body1",}, "You don't own any entities."    )
    );
  } else {
    innerContent = (
      React.createElement(List, null
        , value
          ?.sort((a, b) =>
            (a.metadata.title ?? a.metadata.name).localeCompare(
              b.metadata.title ?? b.metadata.name,
            ),
          )
          .map(entity => (
            React.createElement(ListItem, { key: stringifyEntityRef(entity),}
              , React.createElement(Link, {
                to: catalogEntityRoute(entityRouteParams(entity)),
                component: RouterLink,}
              
                , React.createElement(ListItemText, {
                  primary: entity.metadata.title ?? entity.metadata.name,}
                )
              )
            )
          ))
      )
    );
  }
  return React.createElement(InfoCard, { ...props,}, innerContent);
};
