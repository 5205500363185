import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,


} from '@backstage/core-components';
import {
  CatalogKindHeader,
  CatalogTable,

} from '@backstage/plugin-catalog';
import {
  CatalogFilterLayout,
  EntityListProvider,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,

  UserListPicker,
} from '@backstage/plugin-catalog-react';
import React from 'react';

/**
 * Props for root catalog pages.
 *
 * @public
 */








export const CustomCatalogPage = ({
  columns,
  actions,
  initiallySelectedFilter = 'owned',
  initialKind = 'group',
}) => {
  return (
    React.createElement(PageWithHeader, { title: 'Showpad Catalog', themeId: "home",}
      , React.createElement(EntityListProvider, null
        , React.createElement(Content, null
          , React.createElement(ContentHeader, {
            titleComponent: 
              React.createElement(CatalogKindHeader, {
                initialFilter: initialKind,
                allowedKinds: ['component', 'group', 'user'],}
              )
            ,}
          
            , React.createElement(SupportButton, null, "All your software catalog entities"    )
          )
          , React.createElement(CatalogFilterLayout, null
            , React.createElement(CatalogFilterLayout.Filters, null
              , React.createElement(EntityTypePicker, null )
              , React.createElement(UserListPicker, { initialFilter: initiallySelectedFilter,} )
              , React.createElement(EntityOwnerPicker, null )
              , React.createElement(EntityTagPicker, null )
            )
            , React.createElement(CatalogFilterLayout.Content, null
              , React.createElement(CatalogTable, { columns: columns, actions: actions,} )
            )
          )
        )
      )
    )
  );
};
