
import { Link, Progress, ResponseErrorPanel } from '@backstage/core-components';

import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import pluralize from 'pluralize';
import { useGetEntities } from './useGetEntities';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
      padding: theme.spacing(2),
      color: '#fff',
      transition: `${theme.transitions.duration.standard}ms`,
      '&:hover': {
        boxShadow: theme.shadows[4],
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold ,
    },
    entityTypeBox: {
      background: (props) =>
        theme.getPageTheme({ themeId: props.type }).backgroundImage,
    },
  }),
);

const EntityCountTile = ({
  counter,
  type,
  name,
  url,
}




) => {
  const classes = useStyles({ type });

  return (
    React.createElement(Link, { to: url, variant: "body2",}
      , React.createElement(Box, {
        className: `${classes.card} ${classes.entityTypeBox}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",}
      
        , React.createElement(Typography, { className: classes.bold, variant: "h6",}
          , counter
        )
        , React.createElement(Typography, { className: classes.bold, variant: "h6",}
          , pluralize(name, counter)
        )
      )
    )
  );
};

export const CustomComponentsGrid = ({
  entity,
  relationsType,
  isGroup,
  entityFilterKind,
}




) => {
  const { componentsWithCounters, loading, error } = useGetEntities(
    entity,
    relationsType,
    isGroup,
    entityFilterKind,
  );

  if (loading) {
    return React.createElement(Progress, null );
  } else if (error) {
    return React.createElement(ResponseErrorPanel, { error: error,} );
  }

  const isApi = (type) =>
    ['openapi', 'asyncapi', 'graphql', 'grpc'].includes(type.toLowerCase());

  return (
    React.createElement(Grid, { container: true,}
      , componentsWithCounters?.map(c => (
        React.createElement(Grid, { item: true, xs: 6, md: 6, lg: 4, key: c.name,}
          , React.createElement(EntityCountTile, {
            counter: c.counter,
            type: c.type,
            name: c.name,
            url: `/${isApi(c.type) ? 'api-docs' : 'catalog'}?${c.queryParams}`,}
          )
        )
      ))
    )
  );
};
