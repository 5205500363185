import {

  RELATION_MEMBER_OF,
  RELATION_PARENT_OF,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {

  catalogApiRef,
  getEntityRelations,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import limiterFactory from 'p-limit';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import qs from 'qs';

const limiter = limiterFactory(10);







const getQueryParams = (
  ownersEntityRef,
  selectedEntity,
) => {
  const { kind, type } = selectedEntity;
  const owners = ownersEntityRef.map(owner =>
    humanizeEntityRef(parseEntityRef(owner), { defaultKind: 'group' }),
  );
  const filters = {
    kind,
    type,
    owners,
    user: 'all',
  };
  return qs.stringify({ filters }, { arrayFormat: 'repeat' });
};

const getOwnersEntityRef = (owner) => {
  let owners = [stringifyEntityRef(owner)];
  if (owner.kind === 'User') {
    const ownerGroups = getEntityRelations(owner, RELATION_MEMBER_OF, {
      kind: 'Group',
    });
    const ownerGroupsName = ownerGroups.map(ownerGroup =>
      stringifyEntityRef({
        kind: ownerGroup.kind,
        namespace: ownerGroup.namespace,
        name: ownerGroup.name,
      }),
    );
    owners = [...owners, ...ownerGroupsName];
  }
  return owners;
};

const getAggregatedOwnersEntityRef = async (
  parentGroup,
  catalogApi,
) => {
  const requestedEntities = [];
  const outstandingEntities = new Map();
  const processedEntities = new Set();
  requestedEntities.push(parentGroup);
  let currentEntity = parentGroup;

  while (requestedEntities.length > 0) {
    const childRelations = getEntityRelations(
      currentEntity,
      RELATION_PARENT_OF,
      {
        kind: 'Group',
      },
    );

    await Promise.all(
      childRelations.map(childGroup =>
        limiter(async () => {
          const promise = catalogApi.getEntityByRef(childGroup);
          outstandingEntities.set(childGroup.name, promise);
          try {
            const processedEntity = await promise;
            if (processedEntity) {
              requestedEntities.push(processedEntity);
            }
          } finally {
            outstandingEntities.delete(childGroup.name);
          }
        }),
      ),
    );
    requestedEntities.shift();
    processedEntities.add(
      stringifyEntityRef({
        kind: currentEntity.kind,
        namespace: currentEntity.metadata.namespace,
        name: currentEntity.metadata.name,
      }),
    );
    // always set currentEntity to the first element of array requestedEntities
    currentEntity = requestedEntities[0];
  }

  return Array.from(processedEntities);
};

export function useGetEntities(
  entity,
  relationsType,
  isGroup,
  entityFilterKind,
)










 {
  const catalogApi = useApi(catalogApiRef);
  const kinds = entityFilterKind ?? ['Component', 'API', 'System'];

  const {
    loading,
    error,
    value: componentsWithCounters,
  } = useAsync(async () => {
    const owners =
      relationsType === 'aggregated' && isGroup
        ? await getAggregatedOwnersEntityRef(entity, catalogApi)
        : getOwnersEntityRef(entity);
    const ownedEntitiesList = await catalogApi.getEntities({
      filter: [
        {
          kind: kinds,
          'relations.ownedBy': owners,
        },
      ],
      fields: [
        'kind',
        'metadata.name',
        'metadata.namespace',
        'spec.type',
        'relations',
      ],
    });

    const counts = ownedEntitiesList.items.reduce(
      (acc, ownedEntity) => {
        const match = acc.find(
          x =>
            x.kind === ownedEntity.kind &&
            x.type === (ownedEntity.spec?.type ?? ownedEntity.kind),
        );
        if (match) {
          match.count += 1;
        } else {
          acc.push({
            kind: ownedEntity.kind,
            type: ownedEntity.spec?.type?.toString() ?? ownedEntity.kind,
            count: 1,
          });
        }
        return acc;
      },
      [],
    );

    // Return top N (six) entities to be displayed in ownership boxes
    const topN = counts.sort((a, b) => b.count - a.count).slice(0, 6);

    return topN.map(topOwnedEntity => ({
      counter: topOwnedEntity.count,
      type: topOwnedEntity.type,
      name: topOwnedEntity.type.toLocaleUpperCase('en-US'),
      queryParams: getQueryParams(owners, topOwnedEntity),
    })) 




;
  }, [catalogApi, entity, relationsType]);

  return {
    componentsWithCounters,
    loading,
    error,
  };
}
