

export const ANNOTATION_SHOWPAD_ROLE_LEAD = 'showpad.com/role-lead';
export const ANNOTATION_SHOWPAD_ROLE_SCRUM = 'showpad.com/role-scrum';
export const ANNOTATION_SHOWPAD_ROLE_PM = 'showpad.com/role-pm';

export const getCsvAnnotation = (
  entity,
  annotation,
) => {
  return (
    entity.metadata?.annotations?.[annotation]
      ?.split(',')
      ?.map(el => el.trim()) || []
  );
};
