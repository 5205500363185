import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { oktaAuthApiRef } from '@backstage/core-plugin-api';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { CatalogGraphPage, Direction } from '@backstage/plugin-catalog-graph';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import React from 'react';
import { Route } from 'react-router';
import { apis } from './apis';
import { CustomApiExplorerPage } from './components/ApiExplorerPage/CustomApiExplorerPage';
import { entityPage } from './components/catalog/EntityPage';
import { CustomCatalogPage } from './components/CatalogPage/CustomCatalogPage';
import { Root } from './components/Root';
import { searchPage } from './components/search/SearchPage';

const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      React.createElement(SignInPage, {
        ...props,
        provider: {
          id: 'okta-auth-provider',
          title: 'Okta',
          message: 'Sign in using Okta',
          apiRef: oktaAuthApiRef,
        },}
      )
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  React.createElement(FlatRoutes, null
    , React.createElement(Route, { path: "/", element: React.createElement(HomepageCompositionRoot, null ),}
      , React.createElement(HomePage, null )
    )
    , React.createElement(Route, { path: "/catalog", element: React.createElement(CatalogIndexPage, null ),}
      , React.createElement(CustomCatalogPage, { initialKind: "group",} )
    )
    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name",
      element: React.createElement(CatalogEntityPage, null ),}
    
      , entityPage
    )
    , React.createElement(Route, { path: "/docs", element: React.createElement(TechDocsIndexPage, null ),} )
    , React.createElement(Route, {
      path: "/docs/:namespace/:kind/:name/*",
      element: React.createElement(TechDocsReaderPage, null ),}
    
      , React.createElement(TechDocsAddons, null
        , React.createElement(ReportIssue, null )
      )
    )
    , React.createElement(Route, { path: "/api-docs", element: React.createElement(CustomApiExplorerPage, null ),} )
    , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}
      , searchPage
    )
    , React.createElement(Route, {
      path: "/graph",
      element: 
        React.createElement(CatalogGraphPage, {
          initialState: {
            rootEntityRefs: ['group:pne'],
            selectedKinds: ['Group', 'API'],
            maxDepth: 2,
            direction: Direction.LEFT_RIGHT,
          },}
        )
      ,}
    )
    , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),} )
    /* <Route path="/create" element={<ScaffolderPage />} /> */
    /* <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    /> */
  )
);

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null )
    , React.createElement(OAuthRequestDialog, null )
    , React.createElement(AppRouter, null
      , React.createElement(Root, null, routes)
    )
  )
);

export default App;
