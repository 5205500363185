import { InfoCard, } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Switch,
  Tooltip,
} from '@material-ui/core';
import React, { useState } from 'react';
import { CustomComponentsGrid as CustomComponentsGrid } from './CustomComponentsGrid';

const useStyles = makeStyles(theme => ({
  list: {
    [theme.breakpoints.down('xs')]: {
      padding: `0 0 12px`,
    },
  },
  listItemText: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  listItemSecondaryAction: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      top: 'auto',
      right: 'auto',
      position: 'relative',
      transform: 'unset',
    },
  },
}));

/** @public */
export const CustomOwnershipCard = (props




) => {
  const { variant, entityFilterKind, hideRelationsToggle, relationsType } =
    props;
  const relationsToggle =
    hideRelationsToggle === undefined ? false : hideRelationsToggle;
  const classes = useStyles();
  const { entity } = useEntity();
  const isGroup = entity.kind === 'Group';
  const [getRelationsType, setRelationsType] = useState(
    relationsType || 'direct',
  );

  return (
    React.createElement(InfoCard, { title: "Ownership", variant: variant,}
      , !relationsToggle && (
        React.createElement(List, { dense: true,}
          , React.createElement(ListItem, { className: classes.list,}
            , React.createElement(ListItemText, { className: classes.listItemText,} )
            , React.createElement(ListItemSecondaryAction, {
              className: classes.listItemSecondaryAction,}
            , "Direct Relations"

              , React.createElement(Tooltip, {
                placement: "top",
                arrow: true,
                title: `${
                  getRelationsType === 'direct' ? 'Direct' : 'Aggregated'
                } Relations`,}
              
                , React.createElement(Switch, {
                  color: "primary",
                  checked: getRelationsType !== 'direct',
                  onChange: () =>
                    getRelationsType === 'direct'
                      ? setRelationsType('aggregated')
                      : setRelationsType('direct')
                  ,
                  name: "pin",
                  inputProps: { 'aria-label': 'Ownership Type Switch' },
                  disabled: !isGroup,}
                )
              ), "Aggregated Relations"

            )
          )
        )
      )
      , React.createElement(CustomComponentsGrid, {
        entity: entity,
        relationsType: getRelationsType,
        isGroup: isGroup,
        entityFilterKind: entityFilterKind,}
      )
    )
  );
};
