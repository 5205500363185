/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  DEFAULT_NAMESPACE,

  stringifyEntityRef,

} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  Link,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  entityRouteParams,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,

  Typography,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { generatePath } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import {
  ANNOTATION_SHOWPAD_ROLE_LEAD,
  ANNOTATION_SHOWPAD_ROLE_PM,
  ANNOTATION_SHOWPAD_ROLE_SCRUM,
  getCsvAnnotation,
} from '../../annotations';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
      overflow: 'visible',
      position: 'relative',
      margin: theme.spacing(4, 1, 1),
      flex: '1',
      minWidth: '0px',
    },
    email: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '100%',
      '&:hover': {
        overflow: 'visible',
        whiteSpace: 'normal',
      },
    },
  }),
);

const MemberComponent = (props) => {
  const classes = useStyles();
  const {
    metadata: { name: metaName, description },
    spec: { profile },
  } = props.member;
  const displayName = profile?.displayName ?? metaName;

  return (
    React.createElement(Grid, { item: true, container: true, xs: 12, sm: 6, md: 4, xl: 2,}
      , React.createElement(Box, { className: classes.card,}
        , React.createElement(Box, {
          display: "flex",
          flexDirection: "column",
          m: 3,
          alignItems: "center",
          justifyContent: "center",}
        
          , React.createElement(Avatar, {
            displayName: displayName,
            picture: profile?.picture,
            customStyles: {
              position: 'absolute',
              top: '-2rem',
            },}
          )
          , React.createElement(Box, {
            pt: 2,
            sx: {
              maxWidth: '100%',
            },
            textAlign: "center",}
          
            , React.createElement(Typography, { variant: "h5",}
              , React.createElement(Link, {
                to: generatePath(
                  `/catalog/:namespace/user/${metaName}`,
                  entityRouteParams(props.member),
                ),}
              
                , displayName
              )
            )
            , profile?.email && (
              React.createElement(Link, { className: classes.email, to: `mailto:${profile.email}`,}
                , profile.email
              )
            )
            , description && (
              React.createElement(Typography, { variant: "subtitle2",}, description)
            )
          )
        )
      )
    )
  );
};

/** @public */
export const MembersListCard = (props


) => {
  const { memberDisplayTitle = 'Members', pageSize = 50 } = props;

  const { entity: groupEntity } = useEntity();
  const {
    metadata: { name: groupName, namespace: grpNamespace },
    spec: { profile },
  } = groupEntity;
  const catalogApi = useApi(catalogApiRef);

  const displayName = profile?.displayName ?? groupName;

  const groupNamespace = grpNamespace || DEFAULT_NAMESPACE;

  const [page, setPage] = React.useState(1);
  const pageChange = (_, pageIndex) => {
    setPage(pageIndex);
  };

  const roleLeads = getCsvAnnotation(groupEntity, ANNOTATION_SHOWPAD_ROLE_LEAD);
  const rolePMs = getCsvAnnotation(groupEntity, ANNOTATION_SHOWPAD_ROLE_PM);
  const roleScrums = getCsvAnnotation(
    groupEntity,
    ANNOTATION_SHOWPAD_ROLE_SCRUM,
  );

  const {
    loading,
    error,
    value: members,
  } = useAsync(async () => {
    const membersList = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'relations.memberof': [
          stringifyEntityRef({
            kind: 'group',
            namespace: groupNamespace.toLocaleLowerCase('en-US'),
            name: groupName.toLocaleLowerCase('en-US'),
          }),
        ],
      },
    });

    return (membersList.items ).map((m) => {
      let roles = [];
      // Update description to reflect the role in the team
      if (roleLeads.indexOf(m.metadata.name) >= 0) {
        roles.push('Engineering Lead');
      }
      if (rolePMs.indexOf(m.metadata.name) >= 0) {
        roles.push('Product Owner');
      }
      if (roleScrums.indexOf(m.metadata.name) >= 0) {
        roles.push('Scrum Master');
      }

      if (roles.length) {
        m.metadata.description = roles.join(' / ');
      }

      return m;
    });
  }, [catalogApi, groupEntity]);

  if (loading) {
    return React.createElement(Progress, null );
  } else if (error) {
    return React.createElement(ResponseErrorPanel, { error: error,} );
  }

  const nbPages = Math.ceil((members?.length || 0) / pageSize);
  const paginationLabel = nbPages < 2 ? '' : `, page ${page} of ${nbPages}`;

  const pagination = (
    React.createElement(Pagination, {
      count: nbPages,
      page: page,
      onChange: pageChange,
      showFirstButton: true,
      showLastButton: true,}
    )
  );

  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(InfoCard, {
        title: `${memberDisplayTitle} (${
          members?.length || 0
        }${paginationLabel})`,
        subheader: `of ${displayName}`,
        ...(nbPages <= 1 ? {} : { actions: pagination }),}
      
        , React.createElement(Grid, { container: true, spacing: 3,}
          , members && members.length > 0 ? (
            members
              .slice(pageSize * (page - 1), pageSize * page)
              .map(member => (
                React.createElement(MemberComponent, { member: member, key: member.metadata.uid,} )
              ))
          ) : (
            React.createElement(Box, { p: 2,}
              , React.createElement(Typography, null, "This group has no "
                    , memberDisplayTitle.toLocaleLowerCase(), "."
              )
            )
          )
        )
      )
    )
  );
};
